<template>
    <section class="section-legal">
        <h1>Mentions légales</h1>
        <h2>Direction éditoriale</h2>
        <p>
            MARJONAT DISTRIBUTION<br />
            Batiment C CS 20424<br />
            425 rue René Descartes<br />
            13591 Aix en Provence<br />
            Cedex 3
        </p>
        <p>Numéro SIREN : 801 584 772 RCS d »Aix en Provence</p>
        <div class="sep-legal"></div>
        <h2>Conception & web-design</h2>
        <p>
            SYNTHES’3D SAS<br />
            4, Rue Mahatma Gandhi<br />
            Espace Beauvalle – Bât. B<br />
            13 090 Aix-en-Provence<br />
            France<br />
            Tél. : +33 (0)4 13 10 15 20<br />
            E-mail : contact@synthes3d.com
        </p>
        <div class="sep-legal"></div>
        <h2>Hébergeur</h2>
        <p>
            SAS OVH<br />
            2 rue Kellermann<br />
            F-59 100 Roubaix
        </p>
    </section>
</template>

<script>
export default {
    name : "LegalNotices"
}
</script>


<style lang="scss" scoped>
@import "./../scss/variables.scss";
    .section-legal {
        padding-top:200px;
        padding-bottom:100px;
        width:90%;
        max-width: 1200px;
        margin:0 auto;
    }

    .section-legal h1 {
        font-size:40px;
        font-weight: 700;
        text-transform: uppercase;
        text-align: center;
        padding:0;
        margin: 0 0 80px 0;
        color:$mainColor;
    }

    .section-legal h2 {
        font-size:30px;
        color:$mainColor;
        font-weight: 400;
        text-align: left;
        padding:0;
        margin: 0 0 20px 0;
    }

    .section-legal p {
        font-size:18px;
        color:$secondColor;
        font-weight: 400;
        text-align: left;
        padding:0;
        margin: 0 0 10px 0;
    }

    .sep-legal {
        width: 60%;
        height:1px;
        background:$mainColor;
        margin:80px  auto;
    }
</style>